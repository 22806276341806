import React, { Component } from 'react'
import Dollar from '../icon/card/Dollar'
import DownPay from './../icon/card/DownPay.svg'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import Axios from 'axios'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

const PrettoSlider = styled(Slider)({
  color: '#d22646',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 10,
    fontSize: 20,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    color: '#d22646',
    borderRadius: '50% 50% 50% 0',
    // backgroundColor: '#0d2199',
    backgroundColor: 'white',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})
class Withdraw extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      slideUp: 0,
      provider: null,
      maxWithdraw: null,
      blockId: null,
      payment: null,
      usdt: null,
      USDTContract: null,
      airdrop: null,
      accounts: null,
      id: null,
      user: null,
      server: null,
      wait: false,
      w3: null,
      state: null,
      inputValid: false,
      color: {
        darkBlue: '#0d2199',
        blue: '#2b398d',
        darkRed: '#bf2228',
        red: '#d22646',
        green: '#6fcd84',
        greenBlue: '#1ca1b6',
        font: '#72878d',
        fontBlue: '#2b398d',
        already: '#bababa',
      },
    }
  }
  componentDidUpdate() {
    if (this.props.accounts !== undefined) {
      if (this.state.accounts !== this.props.accounts) {
        this.setState({ accounts: this.props.accounts })
      }
    }
    if (this.props.payment !== undefined) {
      if (this.state.payment !== this.props.payment) {
        this.setState({ payment: this.props.payment })
      }
    }
    if (this.props.usdt !== undefined) {
      if (this.state.usdt !== this.props.usdt) {
        this.setState({ usdt: this.props.usdt })
      }
    }
    if (this.props.USDTContract !== undefined) {
      if (this.state.USDTContract !== this.props.USDTContract) {
        this.setState({ USDTContract: this.props.USDTContract })
      }
    }
    if (this.props.airdrop !== undefined) {
      if (this.state.airdrop !== this.props.airdrop) {
        this.setState({ airdrop: this.props.airdrop })
      }
    }
    if (this.props.id !== undefined) {
      if (this.state.id !== this.props.id) {
        this.setState({ id: this.props.id })
      }
    }
    if (this.props.provider !== undefined) {
      if (this.state.provider !== this.props.provider) {
        this.setState({ provider: this.props.provider })
      }
    }
    if (this.props.user !== undefined) {
      if (this.state.user !== this.props.user) {
        this.setState({
          user: this.props.user,
          wait: this.props.user.pending === 0 ? false : true,
          maxWithdraw: (this.props.user.amount * Math.pow(10, 18)).toString(),
        })
      }
    }
    if (this.props.server !== undefined) {
      if (this.state.server !== this.props.server) {
        this.setState({ server: this.props.server })
      }
    }
    if (this.props.w3 !== undefined) {
      if (this.props.w3 !== null) {
        if (this.state.w3 !== this.props.w3) {
          this.setState({ w3: this.props.w3 })
        }
      }
    }
  }
  checkDot = (event) => {
    if (event.target.value !== '') {
      let balance = parseFloat(
        new BigNumber(this.state.maxWithdraw).dividedBy(Math.pow(10, 18)),
      )
      if (!event.target.value.toString().includes('.')) {
        this.setState({ dot: false })
      }
      if (event.target.value <= balance) {
        if (!event.target.value.toString().includes('.')) {
          this.setState({
            value: parseFloat(event.target.value),
            slideUp: Math.floor(
              100 * (parseFloat(event.target.value) / balance),
            ),
          })
        } else if (event.target.value.split('.')[1].length > 4) {
          this.setState({
            value: parseFloat(event.target.value).toFixed(5).slice(0, -1),
            slideUp: Math.floor(
              100 *
                (parseFloat(event.target.value).toFixed(5).slice(0, -1) /
                  balance),
            ),
          })
        } else {
          this.setState({
            value: event.target.value,
            slideUp: Math.floor(
              100 * (parseFloat(event.target.value) / balance),
            ),
          })
        }
      } else {
        if (balance.toString().includes('.')) {
          this.setState({
            dot: true,
            value: balance.toFixed(5).slice(0, -1),
            slideUp: 100,
          })
        } else {
          this.setState({
            value: balance.toFixed(5).slice(0, -1),
            slideUp: 100,
          })
        }
      }
    } else {
      this.setState({ value: 0, slideUp: 0 })
    }
  }
  validateNumber = (evt) => {
    let key
    var theEvent = evt || window.event
    // Handle paste
    if (theEvent.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain')
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)
    }
    var regex = /[0-9.]/
    // var regex = /[0-9]|\./;
    if (!regex.test(key) || (this.state.dot && key === '.')) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    } else {
      if (!this.state.dot) {
        this.setState({ dot: true })
      }
    }
  }
  setValue(value) {
    if (this.state.maxWithdraw) {
      let balance = parseFloat(
        new BigNumber(this.state.maxWithdraw).dividedBy(Math.pow(10, 18)),
      )
      if (balance > value) {
        this.setState({ value: value })
      } else {
        this.setState({ value: parseFloat(balance).toFixed(5).slice(0, -1) })
      }
    } else {
    }
  }
  valueLabelFormat = (value) => {
    return `${value}%`
  }
  toPlainString = (num) => {
    return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (
      a,
      b,
      c,
      d,
      e,
    ) {
      return e < 0
        ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0)
    })
  }
  withdraw = async () => {
    let user = await Axios.get(
      (this.state.server
        ? 'https://admintest.updowndinero.com/api/get/amount/'
        : 'http://127.0.0.1:8000/api/get/amount/') + this.state.accounts[0],
    )
    const amount = (this.state.value * Math.pow(10, 18)).toString()
    if (amount > 0) {
      if (user.data.pending === 0) {
        this.setState({ wait: true, inputValid: false })
        // let max = parseFloat((new BigNumber(this.state.maxWithdraw).dividedBy(Math.pow(10, 18))));
        let max = user.data.amount
        var address = this.state.accounts[0]
        const message = Web3.utils
          .soliditySha3(
            { t: 'address', v: address },
            { t: 'uint256', v: this.toPlainString(amount) },
          )
          .toString('hex')
        let signature = await Axios.post(
          (this.state.server
            ? 'https://admintest.updowndinero.com/api/get/getSignature/'
            : 'http://127.0.0.1:8000/api/get/amount/') + message+"/" +address,
        )
        signature = signature.data
        let data = await Axios.post(
          this.state.server
            ? 'https://admintest.updowndinero.com/api/pending/payment'
            : 'http://127.0.0.1:8000/api/pending/payment',
          {
            id: this.state.user.id,
            hash: null,
            account: this.state.accounts[0],
            type: 2,
            amount: this.state.value,
            max: user.data.amount - this.state.value,
          },
        )
        this.props.fetchUser()
        try {
          var g = await this.state.airdrop.withdraw(
            data.data,
            this.state.accounts[0],
            this.toPlainString(amount),
            this.toPlainString(max * Math.pow(10, 18)),
            user.data.state,
            signature,
          )
          await g.wait()
          await Axios.post(
            this.state.server
              ? 'https://admintest.updowndinero.com/api/payment'
              : 'http://127.0.0.1:8000/api/payment',
            {
              transactionId: data.data,
              id: this.state.user.id,
              hash: g.hash,
              account: this.state.accounts[0],
              type: 2,
            },
          )
          this.setState({ wait: false, slideUp: 0, value: 0 })
        } catch (e) {
          console.log(e)
          // if (e.code === 4001) {
          await Axios.post(
            this.state.server
              ? 'https://admintest.updowndinero.com/api/payment'
              : 'http://127.0.0.1:8000/api/payment',
            {
              transactionId: data.data,
              id: this.state.user.id,
              hash: g ?? null ? g.hash : null,
              account: this.state.accounts[0],
              type: 2,
            },
          )
          this.setState({ wait: false, slideUp: 0, value: 0 })
          // }
        }
        this.props.fetchUser()
      } else {
        this.setState({ wait: true, inputValid: false, slideUp: 0, value: 0 })
      }
    } else {
      this.setState({ inputValid: true })
    }
  }
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="card shadow withdraw-card">
          <div
            className="withdraw-margin"
            style={{ color: this.state.color.red }}
          >
            <div
              className="withdraw-title "
              style={{
                fontWeight: 'bold',
                color: this.state.color.darkBlue,
              }}
            >
              Withdraw
            </div>
            <div className="d-flex withdraw-input-margin">
              <Dollar
                className="withdraw-input-dollar"
                style={{
                  fill: this.state.color.darkBlue,
                }}
              />
              <div
                className="withdraw-asset"
                style={{
                  color: this.state.color.darkBlue,
                  fontWeight: 'bold',
                }}
              >
                Asset{' '}
                {(this.state.maxWithdraw / Math.pow(10, 18)).toFixed(2) + ' '}
                USDT
              </div>
            </div>
            <div className="d-flex">
              <h2 className="withdraw-input-amount">Amout</h2>
              <input
                onChange={(event) => {
                  this.checkDot(event)
                }}
                readOnly={
                  this.state.accounts && this.state.wait === false
                    ? false
                    : true
                }
                onKeyPress={this.validateNumber}
                type="text"
                className={
                  'withdraw-input form-control' +
                  (this.state.inputValid ? ' is-invalid' : '')
                }
                style={{
                  borderColor: this.state.inputValid
                    ? '#dc3545'
                    : this.state.color.darkBlue,
                  textAlign: 'right',
                }}
                value={this.state.value}
              />
              <h2 className="withdraw-input-usdt">USDT</h2>
            </div>
            <Box className="withdraw-slide">
              <PrettoSlider
                className="withdraw-slide-main"
                aria-label="Default"
                disabled={this.state.wait}
                value={this.state.slideUp}
                valueLabelFormat={this.valueLabelFormat}
                valueLabelDisplay="on"
                onChange={(event, value) => {
                  let balance =
                    parseFloat(
                      new BigNumber(this.state.maxWithdraw).dividedBy(
                        Math.pow(10, 18),
                      ),
                    ) *
                    (value / 100)
                  this.setState({
                    slideUp: value,
                    value: balance.toFixed(5).slice(0, -1),
                  })
                }}
              />
            </Box>
            <div className="d-flex justify-content-between withdraw-btn-length">
              <button
                className="btn withdraw-btn-round"
                style={{
                  backgroundColor:
                    this.state.wait === false
                      ? this.state.color.red
                      : this.state.color.already,
                }}
                onClick={() => {
                  if (!this.state.wait) {
                    let balance =
                      parseFloat(
                        new BigNumber(this.state.maxWithdraw).dividedBy(
                          Math.pow(10, 18),
                        ),
                      ) * 0.1
                    this.setState({
                      value: balance.toFixed(5).slice(0, -1),
                      slideUp: 10,
                    })
                  }
                }}
              >
                <div className="text-white text-center withdraw-btn-font-size">
                  10%
                </div>
              </button>
              <button
                className="btn withdraw-btn-round"
                style={{
                  backgroundColor:
                    this.state.wait === false
                      ? this.state.color.red
                      : this.state.color.already,
                }}
                onClick={() => {
                  if (!this.state.wait) {
                    let balance =
                      parseFloat(
                        new BigNumber(this.state.maxWithdraw).dividedBy(
                          Math.pow(10, 18),
                        ),
                      ) * 0.25
                    this.setState({
                      value: balance.toFixed(5).slice(0, -1),
                      slideUp: 25,
                    })
                  }
                }}
              >
                <div className="text-white text-center withdraw-btn-font-size">
                  25%
                </div>
              </button>
              <button
                className="btn withdraw-btn-round"
                style={{
                  backgroundColor:
                    this.state.wait === false
                      ? this.state.color.red
                      : this.state.color.already,
                }}
                onClick={() => {
                  if (!this.state.wait) {
                    let balance =
                      parseFloat(
                        new BigNumber(this.state.maxWithdraw).dividedBy(
                          Math.pow(10, 18),
                        ),
                      ) * 0.5
                    this.setState({
                      value: balance.toFixed(5).slice(0, -1),
                      slideUp: 50,
                    })
                  }
                }}
              >
                <div className="text-white text-center withdraw-btn-font-size">
                  50%
                </div>
              </button>
              <button
                className="btn withdraw-btn-round"
                style={{
                  backgroundColor:
                    this.state.wait === false
                      ? this.state.color.red
                      : this.state.color.already,
                }}
                onClick={() => {
                  if (!this.state.wait) {
                    let balance =
                      parseFloat(
                        new BigNumber(this.state.maxWithdraw).dividedBy(
                          Math.pow(10, 18),
                        ),
                      ) * 0.75
                    this.setState({
                      value: balance.toFixed(5).slice(0, -1),
                      slideUp: 75,
                    })
                  }
                }}
              >
                <div className="text-white text-center withdraw-btn-font-size">
                  75%
                </div>
              </button>
              <button
                className="btn withdraw-btn-round"
                style={{
                  backgroundColor:
                    this.state.wait === false
                      ? this.state.color.red
                      : this.state.color.already,
                }}
                onClick={() => {
                  if (!this.state.wait) {
                    let balance = parseFloat(
                      new BigNumber(this.state.maxWithdraw).dividedBy(
                        Math.pow(10, 18),
                      ),
                    )
                    this.setState({
                      value: balance.toFixed(5).slice(0, -1),
                      slideUp: 100,
                    })
                  }
                }}
              >
                <div className="text-white text-center withdraw-btn-font-size">
                  Max
                </div>
              </button>
            </div>
            <div className="withdraw-btn-confirm-margin">
              <button
                onClick={async () => {
                  if (
                    (this.state.accounts === null ? [] : this.state.accounts)
                      .length > 0
                  ) {
                    this.withdraw()
                  } else {
                    this.props.reConnect()
                  }
                }}
                className="btn text-center withdraw-btn-confirm"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url('${DownPay}')`,
                }}
              >
                <div
                  className="withdraw-btn-confirm-font-size"
                  style={{
                    color: 'white',
                  }}
                >
                  Withdraw
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Withdraw

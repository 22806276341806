import { Routes, Route } from 'react-router-dom'
import UpPay from './icon/card/UpPay.svg'
import Navbar from './component/Navbar'
import Index from './component/Index'
import Profile from './component/Profile'
import Withdraw from './component/Withdraw'
import Deposit from './component/Deposit'
import React from 'react'
import Axios from 'axios'
import getBlockchain from './connect/ethereum.js'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import BigNumber from 'bignumber.js'
import HowToPlay from './component/HowToPlay'
import moment from 'moment'
import 'moment-timezone'

// import Check from './../icon/Check';
// import { ethers } from 'ethers';
class App extends React.Component {
  constructor(props) {
    super(props)
    const { innerWidth, innerHeight } = window
    this.state = {
      provider: null,
      show: 0,
      dot: false,
      payment: null,
      usdt: null,
      USDTContract: null,
      airdrop: null,
      accounts: null,
      balanceUSDT: null,
      user: null,
      valueUp: 0,
      lastPrice: 0,
      fixPrice: 0,
      once: 0,
      blockEnd: [],
      blockAfter: [],
      timeEnd: '',
      timeBlock: '',
      timeNext: '',
      timeNext2: '',
      priceChange: 0,
      timeCount: 0,
      // beet
      pool: 0,
      paynow: null,
      allPool: [],
      upMultiplier: 0,
      downMultiplier: 0,
      poolCal: 0,
      myAmountCal: 0,
      payNowCal: null,
      server: true,
      modal: false,
      select1: false,
      select2: false,
      selectError1: false,
      selectError2: false,
      history: null,
      timeServer: null,
      width: innerWidth,
      height: innerHeight,
      w3: null,
      timeZone: 'timeZone',
      checkLoop: null,
      mobile: innerWidth < 576.01 ? true : false,
      color: {
        darkBlue: '#0d2199',
        blue: '#2b398d',
        darkRed: '#bf2228',
        red: '#d22646',
        green: '#6fcd84',
        greenBlue: '#1ca1b6',
        font: '#72878d',
        fontBlue: '#2b398d',
        already: '#bababa',
      },
    }
  }
  timeCountFunc = (count, start, timeRound, timeChange = null) => {
    //end time
    // let time = (new Date()).getTime();
    // let time = new Date().getTime() - timeChange;
    // let blockTime = new Date(start).getTime();
    // let showTime = count - Math.floor((time - blockTime) / 1000);
    let showTime = 300 - timeChange;
    let miniute = Math.floor(showTime / 60);
    let seconds = showTime - miniute * 60;
    if (miniute === 0 && seconds <= 10) {
      this.setState({
        timeEnd: 1 + miniute + ":" + (seconds < 10 ? "0" + seconds : seconds),
        timeBlock: "Processing...",
        timeNext: miniute + ":" + (seconds < 10 ? "0" + seconds : seconds),
        timeNext2: miniute + 6 + ":" + (seconds < 10 ? "0" + seconds : seconds),
      });
    } else {
      let miniute1 = miniute;
      let seconds1 = seconds;
      if (seconds < 10) {
        miniute1 = miniute - 1;
        seconds1 = seconds + 50;
      } else {
        seconds1 = seconds - 10;
      }
      this.setState({
        timeEnd: miniute + ":" + (seconds < 10 ? "0" + seconds : seconds),
        timeBlock: miniute1 + ":" + (seconds1 < 10 ? "0" + seconds1 : seconds1),
        timeNext: miniute + ":" + (seconds < 10 ? "0" + seconds : seconds),
        timeNext2: miniute + 6 + ":" + (seconds < 10 ? "0" + seconds : seconds),
      });
    }
    let begintime = 0;
    let data = setInterval(async () => {
      // let time = new Date().getTime() - timeChange;
      // let blockTime = new Date(start).getTime();
      // let showTime = count - Math.floor((time - blockTime) / 1000);
      let showTime = 300 - timeChange - begintime++;
      let miniute = Math.floor(showTime / 60);
      let seconds = showTime - miniute * 60;
      let timeShow = miniute + ":" + (seconds < 10 ? "0" + seconds : seconds);
      let notZero = true;
      if (miniute === 0 && seconds === 12) {
        Axios.get(
          (this.state.server
            ? "https://admintest.updowndinero.com/api/get/block/no/"
            : "http://127.0.0.1:8000/api/get/block/no/") +
            this.state.upMultiplier +
            "/" +
            this.state.downMultiplier
        );
      }
      if (timeShow.includes("-") || (miniute === 0 && seconds === 1)) {
        Axios.get(
          (this.state.server
            ? "https://admintest.updowndinero.com/api/get/block/no/"
            : "http://127.0.0.1:8000/api/get/block/no/") +
            this.state.upMultiplier +
            "/" +
            this.state.downMultiplier
        ).then((block) => {
          // console.log(
          //   block.data.end.id !== this.state.blockEnd.id,
          //   block.data.end.id,
          //   this.state.blockEnd.id,
          // )
          if (block.data.end.id !== this.state.blockEnd.id || data !== null) {
            this.setState({
              history: block.data.history.reverse(),
              blockEnd: block.data.end,
              blockAfter: block.data.after,
              timeZone: block.data.timezone,
            });
            setTimeout(() => {
              if (this.state.user !== null) {
                Axios.get(
                  this.state.server
                    ? "https://admintest.updowndinero.com/api/get/block/" +
                        this.state.user.id
                    : "http://127.0.0.1:8000/api/get/block/" +
                        this.state.user.id
                ).then((data) => {
                  this.setState({
                    history: data.data.history.reverse(),
                    timeServer: data.data.time,
                  });
                  this.timeCountFunc(
                    timeRound,
                    block.data.end.start_date,
                    timeRound,
                    data.data.time
                  );
                });
              }
            }, 2000);
            clearInterval(data);
            data = null;
            // var now = moment.utc();
            // var machine = moment.tz
            //   .zone(Intl.DateTimeFormat().resolvedOptions().timeZone)
            //   .utcOffset(now);
            // var server = moment.tz.zone(this.state.timeZone).utcOffset(now);
            // var diffHour = Math.abs(machine - server) / 60;
            // let timeChange =
            //   Math.abs(new Date().getTime() - block.data.time * 1000) +
            //   diffHour * 60 * 60 * 1000;
            // this.timeCountFunc(
            //   timeRound,
            //   block.data.end.start_date,
            //   timeRound,
            //   data.data.time
            // );
            this.fetchBetWay();
            this.fetchPool();
            this.fetchUser();
            notZero = false;
          }
        });
      }
      if (notZero) {
        if (timeShow.includes("-")) {
          miniute = 0;
          seconds = 0;
          timeShow = "00:00";
        }
        if (miniute === 0 && seconds <= 10) {
          this.setState({
            timeEnd: timeShow,
            timeBlock: "Processing...",
            timeNext:
              1 + miniute + ":" + (seconds < 10 ? "0" + seconds : seconds),
            timeNext2:
              miniute + 6 + ":" + (seconds < 10 ? "0" + seconds : seconds),
          });
        } else {
          let miniute1 = miniute;
          let seconds1 = seconds;
          if (seconds < 10) {
            miniute1 = miniute - 1;
            seconds1 = seconds + 50;
          } else {
            seconds1 = seconds - 10;
          }
          this.setState({
            timeEnd: miniute + ":" + (seconds < 10 ? "0" + seconds : seconds),
            timeBlock:
              miniute1 + ":" + (seconds1 < 10 ? "0" + seconds1 : seconds1),
            timeNext: miniute + ":" + (seconds < 10 ? "0" + seconds : seconds),
            timeNext2:
              miniute + 6 + ":" + (seconds < 10 ? "0" + seconds : seconds),
          });
        }
      }
    }, 1000);
    this.setState({ timeCount: data });
    // return timeRound * 1000 - (time - blockTime);
    // end end time
  }; 
  changeWidth = (e) => {
    let innerWidth = e.currentTarget.outerWidth
    let innerHeight = e.currentTarget.outerHeight
    this.setState({
      width: innerWidth,
      innerHeight,
      mobile: innerWidth < 576.01 ? true : false,
    })
  }
  async componentDidMount() {
    window.addEventListener('resize', this.changeWidth.bind(this))
    if (this.state.once === 0) {
      let price = await Axios.get(
        'https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT',
      )
      let block = await Axios.get(
        this.state.server
          ? 'https://admintest.updowndinero.com/api/get/block'
          : 'http://127.0.0.1:8000/api/get/block',
      )
      let getPool = await Axios.get(
        (this.state.server
          ? 'https://admintest.updowndinero.com/api/get/pool/'
          : 'http://127.0.0.1:8000/api/get/pool/') + block.data.after.id,
      )
      let pool = 0
      let upMulti = 0
      let downMulti = 0
      getPool.data.forEach((doc) => {
        pool += doc.amount
        if (doc.way === 'up') {
          upMulti += doc.amount
        } else {
          downMulti += doc.amount
        }
      })
      let downMul =
        downMulti === 0
          ? 1
          : parseFloat(
              (
                (((upMulti * 90) / 100 + (downMulti !== 0 ? downMulti : 1)) *
                  90) /
                100 /
                (downMulti !== 0 ? downMulti : 1)
              ).toFixed(2),
            )
      let upMul =
        upMulti === 0
          ? 1
          : parseFloat(
              (
                (((downMulti * 90) / 100 + (upMulti !== 0 ? upMulti : 1)) *
                  90) /
                100 /
                (upMulti !== 0 ? upMulti : 1)
              ).toFixed(2),
            )
      downMul = downMul < 1 ? 1 : downMul
      upMul = upMul < 1 ? 1 : upMul
      downMul = downMul.toFixed(2)
      upMul = upMul.toFixed(2)
      let timeRound = 240 //20 seconds
      // // timeRound = 12300;
      // // this.setState({ lastPrice: parseFloat(price.data.price).toFixed(2) });
      let priceChange =
        parseFloat(price.data.price).toFixed(2) - block.data.end.start_price
      this.setState({
        history: block.data.history.reverse(),
        upMultiplier: upMul,
        downMultiplier: downMul,
        lastPrice: parseFloat(price.data.price).toFixed(2),
        once: 1,
        blockEnd: block.data.end,
        blockAfter: block.data.after,
        timeZone: block.data.timezone,
        priceChange: priceChange.toFixed(2),
        pool: pool,
        allPool: getPool.data,
        timeServer: block.data.time,
      })
      const {
        provider,
        payment,
        usdt,
        USDTContract,
        airdrop,
        accounts,
        w3,
      }  = await getBlockchain()
      if (accounts) {
        Axios.get(
          (this.state.server
            ? 'https://admintest.updowndinero.com/api/get/amount/'
            : 'http://127.0.0.1:8000/api/get/amount/') + accounts[0],
        ).then(async (user) => {
          if (user.data.agreement) {
            this.setState({ modal: false })
          } else {
            this.setState({ modal: true })
          }
          let payNow = null
          getPool.data.forEach((doc) => {
            if (user.data.id === doc.user_account_id) {
              payNow = doc
            }
          })
          let getPoolCal = await Axios.get(
            (this.state.server
              ? 'https://admintest.updowndinero.com/api/get/pool/'
              : 'http://127.0.0.1:8000/api/get/pool/') + block.data.end.id,
          )
          let poolCal = 0
          let payNowCal = 0
          getPoolCal.data.forEach((doc) => {
            poolCal += doc.amount
            if (doc.user_account_id === this.state.user?.id) {
              payNowCal = doc
            }
          })
          let userBlock = await Axios.get(
            this.state.server
              ? 'https://admintest.updowndinero.com/api/get/block/' + user.data.id
              : 'http://127.0.0.1:8000/api/get/block/' + user.data.id,
          )
          this.setState({
            history: userBlock.data.history.reverse(),
            blockEnd: userBlock.data.end,
            blockAfter: userBlock.data.after,
            timeZone: block.data.timezone,
            provider: provider,
            payment: payment,
            usdt: usdt,
            USDTContract: USDTContract,
            airdrop: airdrop,
            accounts: accounts,
            user: user.data,
            payNow: payNow,
            poolCal: poolCal,
            payNowCal: payNowCal,
            w3: w3,
          })
          setTimeout(async () => {
            if (window.ethereum.isConnected()) {
              let balance
              try {
                balance = await this.state.USDTContract.balanceOf(
                  this.state.accounts[0],
                )
                balance = balance.toString()
              } catch (e) {
                console.log(e)
                balance = 0
              }
              let user = await Axios.get(
                (this.state.server
                  ? 'https://admintest.updowndinero.com/api/get/amount/'
                  : 'http://127.0.0.1:8000/api/get/amount/') +
                  this.state.accounts,
              )
              let payNow = null
              if (this.state.allPool.length > 0) {
                this.state.allPool.forEach((doc) => {
                  if (user.data.id === doc.user_account_id) {
                    payNow = doc
                  }
                })
              }
              let poolCal = 0
              let payNowCal = 0
              getPoolCal.data.forEach((doc) => {
                poolCal += doc.amount
                if (doc.user_account_id === this.state.user?.id) {
                  payNowCal = doc
                }
              })
              this.setState({
                balanceUSDT: balance,
                user: user.data,
                payNow: payNow,
                poolCal: poolCal,
                payNowCal: payNowCal,
              })
            }
          }, 1000)
        })
      }
      setInterval(async () => {
        this.fetchPool()
      }, 10000)
      setInterval(async () => {
        let price = await Axios.get(
          'https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT',
        )
        let priceChange =
          parseFloat(price.data.price).toFixed(2) -
          this.state.blockEnd.start_price
        this.setState({
          lastPrice: parseFloat(price.data.price).toFixed(2),
          priceChange: priceChange.toFixed(2),
        })
      }, 1000)
      setTimeout(() => {
        if (this.state.blockEnd.start_date !== undefined) {
          let showtime = this.timeCountFunc(
            timeRound,
            this.state.blockEnd.start_date,
            timeRound,
            this.state.timeServer
          );
          // setTimeout(async () => {
          //   let block = await Axios.get(
          //     (this.state.server
          //       ? 'https://admintest.updowndinero.com/api/get/block/no/'
          //       : 'http://127.0.0.1:8000/api/get/block/no/') +
          //       this.state.upMultiplier +
          //       '/' +
          //       this.state.downMultiplier,
          //   )
          //   this.setState({
          //     history: block.data.history.reverse(),
          //     blockEnd: block.data.end,
          //     blockAfter: block.data.after,
          //   })
          //   clearInterval(this.state.timeCount)
          //   let timeChange = new Date().getTime() - block.data.time * 1000
          //   this.timeCountFunc(
          //     timeRound,
          //     block.data.end.start_date,
          //     timeRound,
          //     timeChange,
          //   )
          //   this.fetchBetWay()
          //   this.fetchPool()
          //   this.fetchUser()
          //   // setInterval(async () => {
          //   //   let block = await Axios.get(
          //   //     (this.state.server
          //   //       ? 'https://admintest.updowndinero.com/api/get/block/no/'
          //   //       : 'http://127.0.0.1:8000/api/get/block/no/') +
          //   //       this.state.upMultiplier +
          //   //       '/' +
          //   //       this.state.downMultiplier,
          //   //   )
          //   //   this.setState({
          //   //     history: block.data.history.reverse(),
          //   //     blockEnd: block.data.end,
          //   //     blockAfter: block.data.after,
          //   //   })
          //   //   clearInterval(this.state.timeCount)
          //   //   let timeChange = new Date().getTime() - block.data.time * 1000
          //   //   this.timeCountFunc(
          //   //     timeRound,
          //   //     block.data.end.start_date,
          //   //     timeRound,
          //   //     timeChange,
          //   //   )
          //   //   this.fetchBetWay()
          //   //   this.fetchPool()
          //   //   this.fetchUser()
          //   // }, timeRound * 1000)
          // }, showtime)
        }
      }, 500)
    }
    window.ethereum.on(
      'disconnect',
      function (accounts) {
        this.setState({ accounts: null, balanceUSDT: 0 })
      }.bind(this),
    )
    window.ethereum.on(
      'accountsChanged',
      async function (accounts) {
        if (accounts.length > 0 && accounts && this.state.USDTContract) {
          let balance = await this.state.USDTContract.balanceOf(accounts[0])
          let user = await Axios.get(
            (this.state.server
              ? 'https://admintest.updowndinero.com/api/get/amount/'
              : 'http://127.0.0.1:8000/api/get/amount/') + accounts[0],
          )
          let payNow = null
          if (this.state.allPool.length > 0) {
            this.state.allPool.forEach((doc) => {
              if (user.data.id === doc.user_account_id) {
                payNow = doc
              }
            })
          }
          if (user.data.agreement) {
            this.setState({ modal: false })
          } else {
            this.setState({ modal: true })
          }
          this.setState({
            accounts: accounts,
            balanceUSDT: balance,
            user: user.data,
            payNow: payNow,
          })
          let block = await Axios.get(
            this.state.server
              ? 'https://admintest.updowndinero.com/api/get/block/' + user.data.id
              : 'http://127.0.0.1:8000/api/get/block/' + user.data.id,
          )
          this.setState({
            history: block.data.history.reverse(),
            blockEnd: block.data.end,
            blockAfter: block.data.after,
            timeZone: block.data.timezone,
          })
        } else {
          this.setState({ accounts: null, balanceUSDT: 0, user: null })
        }
      }.bind(this),
    )
  }
  reConnect = async () => {
    const {
      payment,
      usdt,
      USDTContract,
      airdrop,
      accounts,
      w3,
    } = await getBlockchain()
    let balance = await USDTContract.balanceOf(accounts[0])
    let user = await Axios.get(
      (this.state.server
        ? 'https://admintest.updowndinero.com/api/get/amount/'
        : 'http://127.0.0.1:8000/api/get/amount/') + accounts[0],
    )
    let payNow = null
    if (this.state.allPool.length > 0) {
      this.state.allPool.forEach((doc) => {
        if (user.data.id === doc.user_account_id) {
          payNow = doc
        }
      })
    }
    if (user.data.agreement) {
      this.setState({ modal: false })
    } else {
      this.setState({ modal: true })
    }
    let block = await Axios.get(
      this.state.server
        ? 'https://admintest.updowndinero.com/api/get/block/' + user.data.id
        : 'http://127.0.0.1:8000/api/get/block/' + user.data.id,
    )
    this.setState({
      history: block.data.history.reverse(),
      blockEnd: block.data.end,
      blockAfter: block.data.after,
      timeZone: block.data.timezone,
      payment: payment,
      Busd: usdt,
      USDTContract: USDTContract,
      airdrop: airdrop,
      accounts: accounts,
      user: user.data,
      balanceUSDT: balance,
      payNow: payNow,
      w3: w3,
    })
  }
  fetchUser = async () => {
    if (this.state.accounts !== null) {
      let user = await Axios.get(
        (this.state.server
          ? 'https://admintest.updowndinero.com/api/get/amount/'
          : 'http://127.0.0.1:8000/api/get/amount/') + this.state.accounts[0],
      )
      if (user.data.agreement) {
        this.setState({ modal: false, user: user.data })
      } else {
        this.setState({ modal: true, user: user.data })
      }
      let block = await Axios.get(
        this.state.server
          ? 'https://admintest.updowndinero.com/api/get/block/' + user.data.id
          : 'http://127.0.0.1:8000/api/get/block/' + user.data.id,
      )
      let balance = await this.state.USDTContract.balanceOf(
        this.state.accounts[0],
      )
      balance = balance.toString()
      this.setState({
        history: block.data.history.reverse(),
        blockEnd: block.data.end,
        blockAfter: block.data.after,
        timeZone: block.data.timezone,
        balanceUSDT: balance,
      })
    }
  }
  fetchBetWay = async () => {
    if (this.state.accounts) {
      let user = null
      if (!this.state.user?.id) {
        user = await Axios.get(
          (this.state.server
            ? 'https://admintest.updowndinero.com/api/get/amount/'
            : 'http://127.0.0.1:8000/api/get/amount/') + this.state.accounts[0],
        )
      }
      let getPoolCal = await Axios.get(
        (this.state.server
          ? 'https://admintest.updowndinero.com/api/get/pool/'
          : 'http://127.0.0.1:8000/api/get/pool/') + this.state.blockEnd.id,
      )
      let payNowCal = null
      let poolCal = 0
      getPoolCal.data.forEach((doc) => {
        poolCal += doc.amount
        if (
          doc.user_account_id ===
          (this.state.user ? this.state.user.id : user.id)
        ) {
          payNowCal = doc
        }
      })
      let getPool = await Axios.get(
        (this.state.server
          ? 'https://admintest.updowndinero.com/api/get/pool/'
          : 'http://127.0.0.1:8000/api/get/pool/') + this.state.blockAfter.id,
      )
      let pool = 0
      let payNow = null
      let upMulti = 0
      let downMulti = 0
      getPool.data.forEach((doc) => {
        pool += doc.amount
        if (doc.way === 'up') {
          upMulti += doc.amount
        } else {
          downMulti += doc.amount
        }
        if (doc.user_account_id === this.state.user.id) {
          payNow = doc
        }
      })
      let downMul =
        downMulti === 0
          ? 1
          : parseFloat(
              (((upMulti * 90) / 100 + (downMulti !== 0 ? downMulti : 1)) *
                90) /
                100 /
                (downMulti !== 0 ? downMulti : 1),
            )
      let upMul =
        upMulti === 0
          ? 1
          : parseFloat(
              (((downMulti * 90) / 100 + (upMulti !== 0 ? upMulti : 1)) * 90) /
                100 /
                (upMulti !== 0 ? upMulti : 1),
            )
      upMul = upMul === 0 ? 1 : upMul
      downMul = downMul === 0 ? 1 : downMul
      downMul = downMul < 1 ? 1 : downMul
      upMul = upMul < 1 ? 1 : upMul
      downMul = downMul.toFixed(2)
      upMul = upMul.toFixed(2)
      this.setState({
        poolCal: poolCal,
        payNowCal: payNowCal,
        upMultiplier: upMul,
        downMultiplier: downMul,
        pool: pool,
        allPool: getPool.data,
        payNow: payNow,
      })
    }
  }
  fetchPool = async () => {
    let price = await Axios.get(
      'https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT',
    )
    let getPoolCal = await Axios.get(
      (this.state.server
        ? 'https://admintest.updowndinero.com/api/get/pool/'
        : 'http://127.0.0.1:8000/api/get/pool/') + this.state.blockEnd.id,
    )
    let payNowCal = null
    let poolCal = 0
    getPoolCal.data.forEach((doc) => {
      poolCal += doc.amount
      if (doc.user_account_id === this.state.user?.id) {
        payNowCal = doc
      }
    })
    let getPool = await Axios.get(
      (this.state.server
        ? 'https://admintest.updowndinero.com/api/get/pool/'
        : 'http://127.0.0.1:8000/api/get/pool/') + this.state.blockAfter.id,
    )
    let pool = 0
    let payNow = null
    let upMulti = 0
    let downMulti = 0
    getPool.data.forEach((doc) => {
      pool += doc.amount
      if (doc.way === 'up') {
        upMulti += doc.amount
      } else {
        downMulti += doc.amount
      }
      if (doc.user_account_id === this.state.user.id) {
        payNow = doc
      }
    })
    let downMul =
      downMulti === 0
        ? 1
        : parseFloat(
            (((upMulti * 90) / 100 + (downMulti !== 0 ? downMulti : 1)) * 90) /
              100 /
              (downMulti !== 0 ? downMulti : 1),
          )
    let upMul =
      upMulti === 0
        ? 1
        : parseFloat(
            (((downMulti * 90) / 100 + (upMulti !== 0 ? upMulti : 1)) * 90) /
              100 /
              (upMulti !== 0 ? upMulti : 1),
          )
    upMul = upMul === 0 ? 1 : upMul
    downMul = downMul === 0 ? 1 : downMul
    downMul = downMul < 1 ? 1 : downMul
    upMul = upMul < 1 ? 1 : upMul
    downMul = downMul.toFixed(2)
    upMul = upMul.toFixed(2)
    let priceChange =
      parseFloat(price.data.price).toFixed(2) - this.state.blockEnd.start_price
    this.setState({
      payNowCal: payNowCal,
      poolCal: poolCal,
      upMultiplier: upMul,
      downMultiplier: downMul,
      lastPrice: parseFloat(price.data.price).toFixed(2),
      priceChange: priceChange.toFixed(2),
      pool: pool,
      allPool: getPool.data,
      payNow: payNow,
    })
  }
  showPopUp = () => {
    return (
      <>
        <Modal id="_modal_popup" show={this.state.modal}>
          <Modal.Header
            style={{
              backgroundImage: `url('${UpPay}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <Modal.Title style={{ lineHeight: '30px', fontSize: 'bold' }}>
              Welcome totUP DOWN Dinero!.
              <p style={{ marginBottom: '0px', color: this.state.color.red }}>
                Once you enter a position,
              </p>
              <p style={{ marginBottom: '0px', color: this.state.color.red }}>
                you cannot cancel or adjust it.
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: '#eeeef0',
              borderRadius: '0px 0px 20px 20px',
            }}
          >
            <Form>
              <div
                key={`inline-checkbox`}
                className="mb-3"
                style={{ color: this.state.color.darkBlue }}
              >
                <Form.Check
                  inline
                  onChange={() =>
                    this.setState({
                      select1: !this.state.select1,
                      selectError1: false,
                    })
                  }
                  label="I understand that Iam using this product at my own risk. Any Losses incurred due to my actions are my own responsibility."
                  name="group1"
                  style={{
                    color: this.state.selectError1
                      ? this.state.color.red
                      : this.state.color.darkBlue,
                  }}
                  type={'checkbox'}
                  id={`inline-checkbox-1`}
                />
                <Form.Check
                  style={{
                    marginTop: '10px',
                    color: this.state.selectError2
                      ? this.state.color.red
                      : this.state.color.darkBlue,
                  }}
                  inline
                  onChange={() =>
                    this.setState({
                      select2: !this.state.select2,
                      selectError2: false,
                    })
                  }
                  label="I understand that this product is still under development. I am participating at my own risk."
                  name="group2"
                  type={'checkbox'}
                  id={`inline-checkbox-2`}
                />
              </div>
            </Form>
            <div className="d-flex justify-content-center mt-3 mb-3">
              <button
                className="btn btn-primary"
                style={{
                  fontWeight: 'bold',
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
                onClick={async () => {
                  let valid = true
                  if (this.state.select1) {
                    this.setState({ selectError1: false })
                  } else {
                    this.setState({ selectError1: true })
                    valid = false
                  }
                  if (this.state.select2) {
                    this.setState({ selectError2: false })
                  } else {
                    this.setState({ selectError2: true })
                    valid = false
                  }
                  if (valid) {
                    this.setState({ modal: false })
                    let url =
                      (this.state.server
                        ? 'https://admintest.updowndinero.com/api/user/agree/'
                        : 'http://127.0.0.1:8000/api/user/agree/') +
                      this.state.user.id
                    await Axios.post(url)
                  }
                }}
              >
                <span style={{ paddingRight: '24px', paddingLeft: '24px' }}>
                  Continue
                </span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
  render() {
    return (
      <>
        <Routes>
          <Route
            w3={this.state.w3}
            element={
              <Navbar
                nowPage={(page) => this.setNowPage(page)}
                mobile={this.state.mobile}
                balanceUSDT={this.state.balanceUSDT}
                user={this.state.user}
                accounts={this.state.accounts}
              />
            }
          >
            <Route
              path="/"
              element={
                <Index
                  state={this.state}
                  reConnect={() => this.reConnect()}
                  fetchUser={() => this.fetchUser()}
                  fetchBetWay={() => this.fetchBetWay()}
                  user={this.state.user}
                />
              }
            ></Route>
            <Route
              path="/profile"
              element={
                <Profile
                  blockEnd={this.state.blockEnd}
                  user={this.state.user}
                  server={this.state.server}
                  setUpMul={(value) => this.setState({ upMulti: value })}
                  setDownMul={(value) => this.setState({ downMulti: value })}
                />
              }
            ></Route>
            <Route
              path="/deposit"
              element={
                <Deposit
                  provider={this.state.provider}
                  user={this.state.user}
                  reConnect={() => this.reConnect()}
                  fetchUser={() => this.fetchUser()}
                  balanceUSDT={this.state.balanceUSDT}
                  accounts={this.state.accounts}
                  payment={this.state.payment}
                  usdt={this.state.usdt}
                  USDTContract={this.state.USDTContract}
                  airdrop={this.state.airdrop}
                  blockId={this.state.blockEnd.id}
                  server={this.state.server}
                  w3={this.state.w3}
                />
              }
            ></Route>
            <Route
              path="/withdraw"
              element={
                <Withdraw
                  provider={this.state.provider}
                  user={this.state.user}
                  reConnect={() => this.reConnect()}
                  fetchUser={() => this.fetchUser()}
                  accounts={this.state.accounts}
                  payment={this.state.payment}
                  usdt={this.state.usdt}
                  USDTContract={this.state.USDTContract}
                  airdrop={this.state.airdrop}
                  blockId={this.state.blockEnd.id}
                  server={this.state.server}
                  w3={this.state.w3}
                />
              }
            ></Route>
            <Route path="/how-to-play" element={<HowToPlay />}></Route>
          </Route>
        </Routes>
        {this.showPopUp()}
      </>
    )
  }
}
// function Main() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App
